<template>
<div>
  <div class="container-fluid">
    <PageTitle />

    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :no-multi-select="true"
      >
        <template #filters>
          <b-col md=3>
            <VSelect 
              v-model="filter.level" 
              placeholder="-- Semua Level --"
              :options="mrBranchLevel" 
              :reduce="v=>v.value" 
              @input="doFilter"
            />
          </b-col>
        </template>
        <template #mo_induk_code="data">
          {{ data.scope.value == 'X' ? 'Root' : data.scope.value }}
        </template>
        <template #mo_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #mo_city="data">
          {{ data.scope.value||'-'  }}
        </template>
        <template #mo_level="data">
          {{ data.scope.item.mbl_name.charAt(0).toUpperCase() + data.scope.item.mbl_name.slice(1) }}
        </template>
        <template #mo_lat="data">
          {{ data.scope.item.mo_lat||'-' }}
        </template>
        <template #mo_lang="data">
          {{ data.scope.item.mo_lang||'-' }}
        </template>
        <template #aksi="data">
          <div v-if="!moduleRole('ViewOnly')" class="btn-toolbar">
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                Ubah Data
              </b-dropdown-item>
              <b-dropdown-item 
                v-if="data.scope.item.mo_level!='pusat'"
                @click="doDelete(data.scope.index, data.scope.item)"
              >
                Hapus
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              v-if="data.scope.item.mo_level!='pusat'"
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==1}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
              >
                Active
              </b-dropdown-item>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==2}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
              >
                Inactive
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <span v-else>-</span>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row>
              <b-col md=3>
                <b-form-group>
                  <template #label>
                    Kantor Level <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.mo_level" 
                    placeholder="-- Pilih Satu --"
                    :options="mrBranchLevel" 
                    :reduce="v=>v.value" 
                    :disabled="!!row.mo_id"
                  />
                  <VValidate 
                    name="Kantor Level" 
                    v-model="row.mo_level" 
                    :rules="mrValidation.mo_level" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md=3>
                <b-form-group>
                  <template #label>
                    Kode Induk <span class="text-danger mr5">*</span>
                  </template>
                  <VSelect 
                    v-model="row.mo_induk_code" 
                    placeholder="-- Pilih Satu --"
                    :options="mrIndukCode" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Kode Induk" 
                    v-model="row.mo_induk_code" 
                    :rules="mrValidation.mo_induk_code" 
                  />
                </b-form-group>
              </b-col>
              <b-col md=3>
                <b-form-group>
                  <template #label>
                    Kode Kantor <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.mo_code"
                    :readonly="row.mo_create_date"
                  />
                  <VValidate 
                    name="Kode Kantor" 
                    v-model="row.mo_code" 
                    :rules="mrValidation.mo_code" 
                  />
                </b-form-group>
              </b-col>

              <b-col md=7>
                <b-form-group>
                  <template #label>
                    Nama Kantor <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.mo_name"
                  />
                  <VValidate 
                    name="Nama Kantor" 
                    v-model="row.mo_name" 
                    :rules="mrValidation.mo_name" 
                  />
                </b-form-group>
              </b-col>

              <b-col md=5>
                <b-form-group>
                  <template #label>
                    Kota <span class="text-danger mr5">*</span>
                  </template>
                  <b-input
                    type="text"
                    v-model="row.mo_city"
                  />
                  <VValidate 
                    name="Kota" 
                    v-model="row.mo_city" 
                    :rules="mrValidation.mo_city" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md=3>
                <b-form-group>
                  <template #label>
                    Latitude
                  </template>
                  <b-input
                    type="number"
                    step="any"
                    v-model="row.mo_lat"
                  />
                </b-form-group>
              </b-col>

              <b-col md=3>
                <b-form-group>
                  <template #label>
                    Longitude
                  </template>
                  <b-input
                    type="number"
                    step="any"
                    v-model="row.mo_lang"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="row.mo_level!='pusat'"
                md="6"
              >
                <b-form-group>
                  <template #label>
                    Status <span class="text-danger mr5">*</span>
                  </template>
                  <b-form-radio-group
                    id="radio-group-2"
                    v-model="row[statusKey]"
                    name="radio-group-2"
                  >
                    <b-form-radio value=1>Active</b-form-radio>
                    <b-form-radio value=2>Inactive</b-form-radio>
                  </b-form-radio-group>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="mrValidation[statusKey]" 
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-2 offset-md-10">
                <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'

export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'mo_id',
      statusKey:'mo_is_active',
      fields:[
        { key: 'select', label: "" },
        { key:'number', label:'#' },
        { key:'mo_code', label:'Kode Kantor' },
        { key:'mo_induk_code', label:'Kode Induk ', is_custom: true },
        { key:'mo_level', label:'Kantor Level', is_custom: true },
        { key:'mo_name', label:'Nama Kantor' },
        { key:'mo_city', label:'Kota', is_custom: true },
        { key:'mo_jml_penaksir', label:'Jumlah Penaksir' },
        { key:'mo_lat', label:'Latitude', is_custom: true },
        { key:'mo_lang', label:'Longitude', is_custom: true },
        { key:'mo_is_active', label:'Status', is_custom:true },
        { key:'aksi', is_custom:true, thClass: 'w_150' },
      ],
      mrIndukCode:[],
      mrBranchLevel:[]
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  }
}
</script>